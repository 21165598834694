/*****************************
  Tabs
*****************************/
.nav-tabs {
	border: none;

	.nav-item {
		margin-bottom: 10px;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

		.nav-link {
			border: none;
			font-size: 16px;
			padding: 12px 30px;
			border-radius: 0;
			font-weight: 600;
			background: $secondary;
			color: $white;

			span {
				display: block;
			}
		}

		.nav-link.active {
			background: $primary;
			color: $white;
		}
	}
}
