/*****************************
  Button
*****************************/
.btn {
	transition: all 0.3s ease-in-out;
	text-transform: uppercase;
}

button {
	outline: medium none !important;
	color: $primary;
}

/* btn */
.btn {
	font-weight: 600;
	font-size: 13px;
	padding: 12px 26px;
	border-radius: $border-radius;


	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}

		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
				}

			}

		}

	}

	&:hover {
		box-shadow: none;
		outline: none;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}

}

.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}

	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}

}

.btn-primary {
	background: $primary;
	border-color: $primary;
	color: $white;
	position: relative;
	&:hover {
		background: $gray-8;
		border-color:  $gray-8;
		color: $white;

		&:not(:disabled) {
			&:not(.disabled).active {
				background: $gray-8;
				border-color: $gray-8;
				color: $white;
			}

			&:not(.disabled) {
				&:active {
					background: $gray-8;
					border-color: $gray-8;
					color: $white;
				}

			}

		}

	}
	&:focus {
		background: $gray-8;
		border-color: $gray-8;
		color: $white;
	}
&:focus {
		background: $gray-8;
		border-color: $gray-8;
		color: $white;
	}
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: $gray-8;
	border-color: $gray-8;
}


.btn-secondary {
	background: $secondary;
	border-color: $secondary;
	color: $white;
	position: relative;
	&:hover {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);

		&:not(:disabled) {
			&:not(.disabled).active {
				background: $secondary;
				border-color: $secondary;
			}

			&:not(.disabled) {
				&:active {
					background: $secondary;
					border-color: $secondary;
				}

			}

		}

	}
	&:focus {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);
	}
&:focus {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);
	}

}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
	background: $secondary;
	border-color: $secondary;
}

.btn-dark {
	background: $gray-9;
	border-color: $gray-9;

	&:hover {
		background: $primary;
		border-color: $primary;
	}

}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}

		}

	}

	i {
		padding-right: 10px;
	}

	+ {
		.btn {
			margin-left: 3px;
		}

	}

}

.btn-link {
	padding: 0;
    color: $primary;
	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-9;

	&:hover {
		background: $gray-2;
		border-color: $gray-2;
		color: $gray-9;
	}

	&:active {
		color: $gray-9;
	}

	&:focus {
		color: $gray-9;
	}

}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-light {
	&:active {
		color: $gray-9;
	}

	&:focus {
		color: $gray-9;
	}

}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn.btn-sm {
	padding: 6px 24px;
}

.btn.btn-md {
	padding: 9px 26px;
}

.btn.btn-lg {
	padding: 13px 30px;
}

.btn.btn-xl {
	padding: 16px 40px;
}

.btn-app {
	display: flex;

	i {
		font-size: 40px;
	}

}

.btn-outline-secondary {
	border: 2px solid $border-color;
	color: $gray-8;

	&:hover {
		background: $gray-8;
		color: $white;
		border-color: $gray-9;
	}

	&:focus {
		background: $gray-9;
		color: $white;
		border-color: $gray-9;
	}

}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
	background: $gray-9;
	color: $white;
	border-color: $gray-9;
}


/* Button Outline */
.btn-outline-light {
	background-color: transparent;
	color: $white;
	border-color: $white;

	&:hover {
		background-color: $black;
		color: $white;
		border-color: $black;
	}
		&:focus {
		background-color: $black;
		color: $white;
		border-color: $black;
	}

	&:not(:disabled) {
		&:not(.disabled).active {
			background-color: $black;
			color: $white;
			border-color: $black;
		}

		&:not(.disabled) {
			&:active {
				background-color: $black;
				color: $white;
				border-color: $black;
			}

		}

	}

}

/* Button Outline */
.btn-outline-primary {
	background-color: transparent;
	color: $primary;
	border-color: $primary;

	&:hover {
		background-color: $black;
		color: $white;
		border-color: $black;
	}

	&:not(:disabled) {
		&:not(.disabled).active {
			background-color: $black;
			color: $white;
			border-color: $black;
		}

		&:not(.disabled) {
			&:active {
				background-color: $black;
				color: $white;
				border-color: $black;
			}

		}

	}

}



.play-video-lg {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 16px;
	background: $white;
	color: $secondary;
	display: block;
	border-radius: 50%;
	text-align: center;
}


.play-video-md {
	width: 70px;
	height: 70px;
	line-height: 70px;
	font-size: 16px;
	background: $white;
	color: $secondary;
	display: block;
	border-radius: 50%;
	text-align: center;
}


.play-video-02 {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 16px;
	background: $primary;
	color: $white;
	display: block;
	border-radius: 0;
	text-align: center;
	&:hover{
		color:$primary;
		background:$white;
	}
	&:focus {
		color:$primary;
		background:$white;
	}
}