$fa-font-path: '../fonts';
@import "./font-awesome/all.min.scss";
@import "./bootstrap/bootstrap.min.css";
@import "./style.scss";
@import "./photos.scss";



/*
<!-- CSS Global Compulsory (Do not remove)-->
<link rel="stylesheet" href="css/font-awesome/all.min.css" />
<link rel="stylesheet" href="css/bootstrap/bootstrap.min.css" />

<!-- Page CSS Implementing Plugins (Remove the plugin CSS here if site does not use that feature)-->
<link rel="stylesheet" href="css/owl-carousel/owl.carousel.min.css" />
<link rel="stylesheet" href="css/swiper/swiper.min.css" />
<link rel="stylesheet" href="css/animate/animate.min.css"/>

<!-- Template Style -->
<link rel="stylesheet" href="css/style.css" />
*/
