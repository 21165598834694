.relative {
  position: relative;
}


.photographer {
  position: absolute;
  right: 25px;
  top: 11px;
  background-color: rgba(0,0,0,.5);
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}

.logo_up {
  margin-top: -30px;
}

.ontop {
  z-index: 5;
}

/*[ Hover img zoom]
-----------------------------------------------------------
*/
.hov-img-zoom {
	display: block;
	overflow: hidden;
}
.hov-img-zoom img{
	width: 100%;
	-webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}
.hov-img-zoom:hover img {
	-webkit-transform: scale(1.1);
  	-moz-transform: scale(1.1);
  	-ms-transform: scale(1.1);
  	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.reklame_container {
  position: relative;
  display: inline-block;
}

.reklame {
  position: absolute;
  right: 5px;
  top: -25px;
  background-color: rgba(0,0,0,.5);
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}

.reklame_inside {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: rgba(0,0,0,.5);
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}

.search {
  border: 1px solid $secondary;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.ad_to_left {
  padding-left: 10px;
  margin-left: 300px;
}

.ad_to_right {
  padding-right: 10px;
  margin-right: 300px;
}
