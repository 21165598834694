/*****************************
    Header
*****************************/
.header {
  z-index: 999;
  position: relative;

  .container-fluid {
    padding: 0 60px;
    align-items: initial;
  }
}

/* Header default */
.header .is-sticky.navbar {
  background: $white;
}

/* topbar */
.header .topbar {
  background: $white;
  padding: 40px 0;
  border-bottom: 1px solid $border-color;

}

.header.bg-dark .topbar {
  border-bottom: 1px solid rgba($white, 0.5);
}

.header .topbar {
  a {
    color: $primary;

    &:hover {
      color: $gray-8;
    }

  }

}

.header .topbar  .dropdown {
  margin-right: 20px;
  .dropdown-toggle {
    padding: 12px 0;
    i {
      font-size: 10px;
    }
  }
  .dropdown-menu {
    a {
      color: $gray-8;
      &:hover {
        color: $primary;
      }
    }
  }
}

.header .topbar .social {
  display: inline-block;
  ul {
    margin: 0;
    li {
      display: inline-block;
      padding-right: 40px;
      a {
        color: $gray-8;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.header .topbar .login {
  display: inline-block;

  a {
    color: $gray-8;

    &:hover {
      color: $primary;
    }

  }

}



// topbar dropdown-menu
.topbar .dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px rgba($black, 0.1);
  padding: 10px;
}

.topbar .dropdown .dropdown-menu a {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 3px;
}

.topbar .dropdown .dropdown-menu a:hover {
  color: $primary;
}

// navbar

.navbar {
  padding: 0;
}

.navbar .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 17px;
  padding: 10px 20px;
  color: $gray-8;
  text-transform: uppercase;
  font-family: $font-hedding;

  i {
    font-weight: bold;
  }

  &:hover {
    color: $primary;
  }

}

.dropdown-menu {
  z-index: 9999;
}

.navbar-collapse {
  position: relative;
}

.navbar-light {
  color: $white;
  background-color: $gray-1;
  border-color: $border-color;
}

.navbar-light .navbar-nav > li > a {
  color: $white;
}

.navbar-light .navbar-nav > .dropdown > a .caret {
  border-top-color: $white;
  border-bottom-color: $white;
}

.navbar-brand {
  padding: 30px 0px;
  margin-right: 0;
}

.navbar-brand img {
  height: 36px;
}

.navbar .navbar-brand {
  color: $white;
  flex: 0 0 180px;
}

.header .navbar-nav > li {
  display: flex;
}

.header .navbar-nav li > a {
  align-items: center;
  display: flex;
  padding: 3px 0px;
  color: $gray-8;
  text-transform: capitalize;
  font-size: 14px;

}
.header .navbar-nav .dropdown-menu li > a {
  border-bottom: 1px solid $border-color;

}

.header .navbar-nav li > a:hover {
  color: $primary;
}

.header .navbar-nav li > a i {
  margin-left: 5px;
  font-size: 10px;
}

.header .add-listing {
  align-self: center;
  flex: 0 0 135px;
  text-align: right;
}

.megamenu {
  padding: 20px 20px;
  width: 100%;
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu .card {
  outline: none;
}

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 1px solid $black;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: $gray-9;
  white-space: normal;
}

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: $gray-9;
  background-color: $gray-1;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: $gray-1;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu.dropdown-header {
  color: $primary;
  font-size: 18px;
}

.header li > .dropdown-item:focus,
.header li > .dropdown-item:hover {
  color: $primary;
  background: none;
}

.header .dropdown-item.active,
.header .dropdown-item:active,
.header .dropdown-item:focus,
.header .dropdown-item:hover {
  background: none;
}

.header .dropdown-toggle::after {
  content: none;
}

.header .navbar-collapse {
  align-items: inherit;
  width: auto;
}

.header .navbar-collapse ul{
  padding-left:80px;
}

.header .megamenu .dropdown-item {
  padding: 0px;
}

.header .navbar .dropdown-menu a.dropdown-item {
  min-width: 180px;
  font-family: $font-hedding;
}
.navbar .navbar-nav .dropdown-menu li.active > a{
  padding-left: 20px !important;
}

.header .navbar .dropdown > .dropdown-menu li > a:hover{
    padding-left: 20px !important;
}

.header .navbar .dropdown-menu {
  padding: 0;

}

.header .navbar .dropdown-menu li a i {
  margin-left: auto;
}

.navbar .dropdown > .dropdown-menu li > a {
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  position: relative;
}

.navbar .dropdown > .dropdown-menu li > a:hover,.navbar .dropdown > .dropdown-menu li.active > a {
    color: $primary;
    padding-left: 15px;
}

/* Header default */
.header.default{
  background: $black;
  .navbar{
     padding: 10px 0;
    .navbar-brand {
    margin-right: 0px;
   }
   .navbar-nav {
     .nav-link{
        color: $black;
        &:hover {
          color: $primary;
        }
      }
     .nav-item.active {
      .nav-link {
         color: $primary;
         }
        }
        .dropdown-menu {
          li.active {
            >a {
              color: $primary;
              padding-left: 10px;
            }
          }
        }
      }
    }
    .topbar{
      .search{
        ul{
          padding-left:0px;
          li{
            list-style: none;
            i{
              font-size: 24px;
              font-weight: 900;
            }
          }
        }
      }
      .dropdown{
        margin-right: 0px;
      }
    }
  }

/* Header center */
  .header.header-center .navbar .navbar-brand {
    display: none;
  }
  .header.header-center .is-sticky.navbar{
     justify-content: space-between !important;
  }
  .header.header-center .is-sticky.navbar .navbar-brand{
     display: block;
     text-align: left;
  }

  .header.header-center.bg-dark .is-sticky.navbar{
    background: $gray-9 !important;

  }


/* Header transparent */
.header.default.header-transparent {
  position: relative;
  background: transparent;
  width: 100%;
  z-index: 999;
  }

.header.default.header-transparent .search ul{
  padding-left:0px;
  margin-bottom: 0px;
}
.header.default.header-transparent .search ul li{
  list-style: none;
}

.header.default.header-transparent .search ul li a{
  color: $primary;
}
.header.default.header-transparent .social ul{
  margin-bottom: 0px;
}
.header.default.header-transparent .social ul li{
  margin-right: 20px;
  display: inline-block;
}
.header.default.header-transparent .social ul li a{
  color: $black;
}
.header.default.header-transparent .social ul li:hover a{
  color: $primary;
}
.navbar .navbar-nav .dropdown-menu li.active > a {
  color: $primary;
  padding-left: 10px;
}

.header-transparent .add-listing {
  align-self: inherit;
  flex: inherit;
  text-align: right;
}

.header-transparent .login a {
  color: $white;

  &:hover {
    color: $primary;
  }
}

.header.default.bg-dark{
  .navbar .navbar-nav {
    .nav-link{
      color: $white;
      &:hover{
        color: $primary;
      }
    }
  }
  .topbar {
    .social{
       ul{
         li{
          a{
             color: $white;
           }
            &:hover{
                a{
                 color: $primary;
                }
             }
         }
       }
    }
  }
}

/* Search */
.search {
  .search-btn {
    display: block;
    height: 22px;
    line-height: 22px;
    position: relative;
    text-indent: -9999px;
    width: 14px;
    color: $primary;
    transition: color 300ms ease 0s;
    transition: 0.5s ease-in-out;
    &:before {
      content: "\f002";
      font-family: "Font Awesome 5 Free";
      font-size: 24px;
      font-weight: 900;
      right: 0;
      position: absolute;
      text-indent: 0;
      top: 0;
    }
  }
  .search-box {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.5s ease-in-out;
    }
  }
    .form-control {
      box-shadow: 0px 5px 10px rgba($black, 0.1);
      border: none;
    }
}
.search.search-open {
  .search-btn {
    &:before {
      content: "\f00d";
    }
  }
  .search-box {
    opacity: 1 !important;
    visibility: visible !important;
    transform: rotateX(0deg);
    .form-control {
      padding-right: 50px;
    }
  }
}
.search-box {
  display: block;
  margin: 0;
  opacity: 0 !important;
  position: absolute;
  right: 0;
  top: 35px;
  transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
  visibility: hidden;
  width: 327px;
  z-index: 888;
  border-radius: 3px;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  transition: all 400ms ease;
}
.search-box.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

// sticky header
.header .is-sticky.navbar {
  position: fixed !important;
  width: 100%;
  background: $white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 0 100px;

  .navbar-brand {
    display: block;
  }

  .header-contact-info {
    display: none !important;
  }

  .navbar-nav {
    .nav-link {
      padding: 10px 20px;
    }

  }

}


// sticky header
.header-transparent .is-sticky.navbar {
  position: fixed !important;
  width: 100%;
  background: $gray-9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 9;

  .header-contact-info {
    display: none !important;
  }

  .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%;
  }

}

.nav-title {
  line-height: 1.6;
}


@media (min-width:992px) {
.header .navbar .dropdown-menu {
  margin: 0px;
  font-size: 14px;
  border-radius: 0px;
  border: none;
  left: 100%;
  transform: translate3d(-50%, 10px, 0);
  transition: all 0.3s ease-in-out;
  transition-property: opacity, visibility, transform;
  transform-origin: top center;
  box-shadow: 0px 5px 10px rgba($black, 0.1);
  visibility: hidden;
  opacity: 0;
  display: block !important;
  border-top: 4px solid $primary;
}

.header .navbar .dropdown-menu.megamenu {
  left: 50%;
}

.navbar-nav .dropdown-menu.dropdown-menu-lg {
  min-width: 630px;
}

.navbar-nav .dropdown-menu.dropdown-menu-md {
  min-width: 450px;
}

.navbar-nav .mega-menu {
  position: static;
}

.header .navbar .dropdown:hover > .dropdown-menu {
  transform: translate3d(-50%, 0, 0);
  visibility: visible;
  opacity: 1;
}

.header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
  left: 100%;
  right: auto;
  transform: translate3d(0, 10px, 0);
}

.header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
  right: 100%;
  left: auto;
  transform: translate3d(-15px, 10px, 0);
}

.navbar-nav li:hover > ul.dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

}

.place-card-large {
display: none !important;
}

@media (max-width:1500px) {

}

@media (max-width:1199px) {

.header .container {
  max-width: 96%;

}
.header.default .navbar.is-sticky{
    padding: 0 30px;
}

}

@media (max-width:991px) {
  // sticky header
  .header .is-sticky {
    .navbar {
      top: 0;
      right: 0;
    }

  }

  .header-right-bg:before {
    left: 0;
    height: 100%;
    transform: inherit;
  }

  .header .is-sticky.navbar {
    padding: 0 20px;
  }

  .header.default {
    padding: 0;

    .navbar-brand {
      display: block;
    }

  }
  .header.default .navbar-toggler {
    color: $gray-8;
    box-shadow: none;
  }

  .header.default.header-transparent .social{
    display: none;
  }
  .header.default .navbar{
    padding:0px 0px !important;
  }
  .header.default .is-sticky.navbar{
    padding: 0px 20px !important;
  }

  /*header-center*/
  .header.header-center.default .topbar{
    display: none;
  }
  .header.header-center .navbar .navbar-brand{
    display: block;
    text-align: left;
  }
  .header.header-center.default .navbar{
   padding: 40px 0;
   justify-content: space-between !important;
  }

  /*header-dark*/
  .header.header-center.default.bg-dark .navbar-toggler{
    color: $white;
  }
  .header.header-center.default.bg-dark .navbar .navbar-nav .nav-link{
    color: $gray-8;
  }

  .header.header-center.default.bg-dark .navbar .navbar-nav .nav-item.active .nav-link{
    color: $primary;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link:after,
  .header .navbar .navbar-nav .nav-item.active .nav-link:before{
    display: none;
  }
  .header .navbar .navbar-nav .nav-item:hover .nav-link:after,
  .header .navbar .navbar-nav .nav-item:hover .nav-link:before{
    display: none;
  }

  .header.default.header-transparent .search ul{
    display: none !important;
  }

  .header.default .navbar-brand img {
    height: 26px;
  }

}

@media (max-width:575px) {
  .header.default .navbar-toggler {
    padding: 0;
    height: 60px;
  }
  .header.default .navbar-brand {
    padding: 20px 0px;
    img{
      height: 24px;
    }
  }

  .banner.slider-02{
    padding: 0;
  }
}

#search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}


#search-overlay.open {
    z-index: 9999999;
    visibility: visible;
    opacity: 1;
    background-color: rgba(255,255,255,.95);
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}


#search-overlay.open {
    background-color: rgba(255,255,255,.95);
}


